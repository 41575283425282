<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>招商资料</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name
                            }}<i :class="{red:detail.revoke_type_state === 1||  detail.shenpi_num > 1}">(<i v-if="detail.revoke_type_state === 1">撤销后，</i>第{{ detail.shenpi_num }}次提交)</i>
                        </div>
                        <div v-if="detail.shenpi[detail.shenpi.length -2].state == 1 && detail.confirm === 0" class="state">
                            等待{{ detail.true_name }}用印
                        </div>
                        <div v-else-if="detail.state == 0 && detail.confirm === 1" class="state">等待用印登记</div>
                        <div v-else class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <!--        <div class="approval-detail-title">-->
                <!--          <span class="approval-detail-tip">审批编号：</span-->
                <!--          ><span class="approval-detail-content">{{ detail.shenpi_no }}</span>-->
                <!--        </div>-->
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.project.cid) }}形式：</span>
                    <span class="approval-detail-content">{{ detail.meet_form_str }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">招商申请日期：</span>
                    <span class="approval-detail-content">{{ detail.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">支持类别：</span>
                    <span class="approval-detail-content">{{
                            detail.categories === 1 ? "赞助" : "捐赠" }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">招商类型：</span>
                    <span class="approval-detail-content">{{
                            detail.is_new === 1 ? "新建" : "已有" }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">企业名称：</span>
                    <span class="approval-detail-content">{{ detail.corporate_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">联系人：</span>
                    <span class="approval-detail-content">{{ detail.contacts }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">拟定金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.amount).toFixed(6)) }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">拟定专项金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.self_amount).toFixed(6))
                        }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">拟定{{ $getTitleByType('会议金额', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.meeting_decimal).toFixed(6))
                        }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">加盖何种印章：</span>
                    <span class="approval-detail-content">公章</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">附件：</span>
                    <div class="approval-detail-file">
                        <a v-for="(file, index) in detail.files" :key="index"
                           :href="file.filePath | filePath" target="_blank">{{ file.fileName }}</a>
                    </div>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>
                <div class="approval-list">
                    <div class="name-container active">
                        <span>{{ detail.true_name | sub_name }}
                          <i class="el-icon-success"></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发起招商资料用印申请</div>
                        </div>
                    </div>

                    <div v-for="(item,index) in detail.shenpi" :key="item.id">
                        <div v-if="index === 0 ? true : detail.shenpi[index-1].state === 1" class="name-container active">
                            <span>{{ item.true_name | sub_name }}
                              <i :class="item.state === 0 || item.state === 3 ? 'el-icon-remove'  : item.state === 1 ? 'el-icon-success' : 'el-icon-error'"></i>
                            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ item.true_name}}
                                        <i v-if="item.state !== 0">
                                           <i v-if="item.is_user === 2"> ({{ item.state | sp_state(index) }})</i>
                                        </i>
                                    </div>
                                    <div v-if="item.sp_time" class="info-time">
                                        {{ item.sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    <div v-if="index === detail.shenpi.length -1">
                                        {{ item.state === 1 ? "已用印" : (item.state === 2 ? "已驳回（" + item.sp_remark + "）"
                                        : "用印确认中") }}
                                    </div>
                                    <div v-else-if="index === detail.shenpi.length -2">{{ item.state === 0 ? "确认中" : "确认知晓" }}</div>
                                    <div v-else>
                                        <div v-if="item.state === 0">{{item.state | sp_state(index)}}</div>
                                        <div v-else>{{ item.sp_remark }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div
                                    v-for="item in detail.copy"
                                    :key="item.id"
                                    class="copy-members-name">
                                    <span>{{item.true_name | sub_name}}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 1 && detail.state == 0 &&  detail.shenpi.every(item=> item.state === 0)"
                    class="approval-btn-left"
                    @click="toRevoke()"
                >撤回
                </el-button>
                <router-link :to="'/home/subMerchants?id=' + pid">
                    <el-button v-if="member_type == 1 && detail.state == 3"
                        class="approval-btn-left">重新提交
                    </el-button>
                </router-link>
                <el-button
                    v-if="(detail.state == 0 && member_type == 1 && isApprovaler) ||
                    (detail.state == 0 && member_type == 0 && detail.confirm === 1) ||
                    (isApprovaler && member_type == 0 && detail.confirm === 0)"
                    class="approval-btn-left"
                    @click="shenpi_rejectedVisible = true">驳回
                </el-button>
                <el-button
                    v-if="detail.state == 0 && member_type == 1 && isApprovaler"
                    :disabled="requestState" :loading="requestState"
                    class="approval-btn-right"
                    @click="contractConfirm"
                >已申请线下用印
                </el-button>
                <el-button
                    v-if="detail.state == 0 && member_type == 0 && detail.confirm === 1"
                    :disabled="requestState" :loading="requestState"
                    class="approval-btn-right"
                    @click="approvalConfirm">已用印
                </el-button>
<!--                <el-button-->
<!--                    v-if="detail.state == 0 && member_type == 0 && detail.confirm === 1"-->
<!--                    :disabled="requestState" :loading="requestState"-->
<!--                    class="approval-btn-right"-->
<!--                    @click="showDialog(2)">用印驳回-->
<!--                </el-button>-->
                <!--<el-button class="approval-btn-right" v-if="member_type == 1 && detail.state == 1 && !detail.file_name" @click="subFile()">提交</el-button>-->
                <el-button
                    v-if="isApprovaler && member_type == 0 && detail.confirm === 0"
                    class="approval-btn-left"
                    @click="showDialog(2)">拒绝
                </el-button>
                <el-button
                    v-if="isApprovaler && member_type == 0 && detail.confirm === 0"
                    class="approval-btn-right"
                    @click="showDialog(1)">通过
                </el-button>
            </div>
        </div>
        <div v-if="detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/merchantsDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                >
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog :before-close="closeDialog" :visible.sync="shenpi_rejectedVisible"
                   center width="30%">
            <span slot="title" class="dialog-title">审批驳回</span>
            <div class="dialog-container flex-center">
                <el-input v-model="remark" placeholder="请输入审批驳回原因"
                          :rows="5" resize="none" type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="requestState" :loading="requestState" type="primary"
                           @click="shenpi_rejected()">确定</el-button>
              </span>
        </el-dialog>
        <el-tooltip placement="top" effect="light">
            <div slot="content">
                <div v-for="(item,index) in detail.shenpi_rejected" :key="index">
                    {{ item.true_name }}{{item.create_time | formatTime}}驳回了审批，驳回原因为：{{item.message}}<br/>
                </div>
            </div>
            <div class="record flex align-center justify-center" v-if="detail.shenpi_rejected.length">驳回<br>记录</div>
        </el-tooltip>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import {genUpToken, qiniuaddr, uploadPath} from "../../tools/CryptoJS";
import {getPathIndex, sub_name, webUrl, ZHDate} from "../../tools";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {ProjectInfo, ElButton},
    name: "merchantsDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            isApprovaler: false,
            editVisible: false,
            shenpi_rejectedVisible: false,
            requestState: false,
            state: -1,
            remark: "",
            detail: {},
            QiniuData: {
                fname: "", //  文件名
                key: "", //文件名字处理
                token: "", //七牛云token
            },
            qiniuaddr: qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
            uploadPath: uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
            files: [],
        };
    },
    created() {
        this.QiniuData.token = genUpToken();
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意用印" : "";
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark)  return  this.$message.error(this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        approvalConfirm() {
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: 1,
                    affirm: 1,//确认传参
                    remark: "同意",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: 1,
                            affirm: 1,//确认传参
                            remark: "同意",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        contractConfirm() {
            this.requestState = true
            this.$api.apiContent
                .zhaoConfirm({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        shenpi_rejected() {
            if (!this.remark) return  this.$message.error("请输入驳回原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    state:2,
                    id: this.pid,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            state:2,
                            id: this.pid,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 2, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        subFile() {
            if (this.files.length === 0) {
                this.$message.error("请上传文件");
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .zhaoshangFile({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    files: JSON.stringify(this.files),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            files: JSON.stringify(this.files),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getZhaoshang({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    this.detail = res.data;
                    this.isApproval();
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.isApprovaler = false;
            this.editVisible = false;
            this.shenpi_rejectedVisible = false;
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = true;
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = index < 3;
                        }
                    }
                }
            });
            let that = this
            setTimeout(function () {
                that.requestState = false
            }, 2000)
        },
        //  上传成功
        uploadSuccess(response, file, fileList) {
            console.log(file);
            console.log(fileList);
            this.files.push({
                filePath: `${this.qiniuaddr}/${response.key}`,
                //在这里你就可以获取到上传到七牛的外链URL了
                fileName: file.name,
            });

            this.loading.close();
        },
        closeFile(position) {
            this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(() => {
                    this.files.splice(position, 1);
                })
                .catch();
        },
        //  上传失败
        uploadError() {
            this.loading.close();
            this.$message({
                message: "上传出错，请重试！",
                type: "error",
                center: true,
            });
        },
        beforeAvatarUpload(file) {
            //                const isJPG = file.type === 'application/pdf';
            //                if (!isJPG) {
            //                    this.$message.error('只能上传PDF格式!');
            //                    return
            //                }
            //这里的key给加上了时间戳，目的是为了防止上传冲突
            this.QiniuData.fname = file.name;
            this.QiniuData.key = `${new Date().getTime()}${file.name}`;
            this.loading = this.$loading({
                lock: true,
                text: "上传中，请稍后",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 1:
                    return "已完成";
                case 2:
                    return "已归档";
                case 3:
                    return "已驳回";
                default:
                    return "已撤销";
            }
        },
        sp_state(type,index) {
            switch (Number(type)) {
                case 0:
                    return index !== 2 ? "审核中" : '确认中';
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;

                            .upfile-list {
                                width: 400px;

                                li {
                                    height: 31px;
                                    border-bottom: 1px dashed #ccc;

                                    a {
                                        display: block;
                                        width: 350px;
                                        height: 30px;
                                        line-height: 30px;
                                        color: #3b77e7;
                                    }

                                    .el-icon-delete {
                                        font-size: 16px;
                                        cursor: pointer;

                                        &:hover {
                                            color: #3b77e7;
                                        }
                                    }
                                }
                            }

                            .info-file {
                                color: #3b77e7;
                                margin-top: 12px;
                                font-size: 16px;
                            }

                            .info-file-up {
                                position: relative;
                                width: 250px;
                                background: transparent;
                                border: 1px solid #3b77e7;
                                font-size: 12px;
                                margin-top: 12px;
                                padding-left: 50px;
                                color: #3b77e7;

                                .info-file-up-logo {
                                    position: absolute;
                                    top: 25%;
                                    left: 20px;
                                }
                            }
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-file {
                    flex: 7;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;

                    a {
                        font-size: 18px;
                        cursor: pointer;
                        margin-bottom: 8px;
                        display: block;
                        color: #3b77e7;

                        &:hover {
                            font-size: 18px;
                        }
                    }
                }

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
